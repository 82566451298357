import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import {
  EntityGroupProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';

import { MembersListCard } from '../../group/MembersListCard';
import { OWNERSHIP_ENTITY_FILTER } from './shared/helpers';

export const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item md={5} sm={12} xs={12}>
          <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
              <Grid item xs={12}>
                <EntityOrphanWarning />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <EntitySwitch>
            <EntitySwitch.Case if={hasRelationWarnings}>
              <Grid item xs={12}>
                <EntityRelationWarning />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
              <Grid item xs={12}>
                <EntityProcessingErrorsPanel />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <Grid item md={12} sm={12} xs={12}>
            <EntityGroupProfileCard variant="gridItem" />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <EntityLinksCard />
          </Grid>
        </Grid>

        <Grid item md={7} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <EntityOwnershipCard
                variant="gridItem"
                entityFilterKind={OWNERSHIP_ENTITY_FILTER}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <MembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
