import { CodeSnippet, Link } from '@backstage/core-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import he from 'he';
import React from 'react';
import {
  StackOverflowAnswer,
  StackOverflowQuestion,
} from './StackOverflowTypes';

export const userCrumb = (
  record: StackOverflowAnswer | StackOverflowQuestion,
) => {
  if (!record) {
    return <></>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
      {record.owner.profile_image ? (
        <img
          style={{ height: 15, borderRadius: '50%' }}
          src={record.owner.profile_image}
          alt={record.owner.display_name}
          referrerPolicy="no-referrer"
        />
      ) : (
        <AccountCircleIcon style={{ height: '17px' }} />
      )}

      {record.owner.userLink ? (
        <Link to={record.owner.userLink}>
          {he.decode(record.owner.display_name)}
        </Link>
      ) : (
        <p>{he.decode(record.owner.display_name)}</p>
      )}

      <p style={{ fontWeight: 'bold' }}>{record.owner.reputation || '0'}</p>
      <p>-</p>
      <p>{new Date(record.creation_date * 1000).toLocaleString()}</p>
    </div>
  );
};

export const questionTags = (tags: string[]) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      {tags?.map(tag => (
        <div
          style={{
            border: '1px solid #61a8da',
            borderRadius: '3px',
            backgroundColor: '#d0ebff',
            color: '#000000de',
            padding: '0.2rem',
          }}
        >
          {tag}
        </div>
      ))}
    </div>
  );
};

export const parsedBody = (body: string) => {
  const codeSnip = he.decode(body).replace(/<\/?code>/g, '__codesnippet__');
  const stripTags = codeSnip.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <>
      {stripTags.split('__codesnippet__').map((el, index) => {
        if (index % 2 === 1) {
          if (el.includes('\n')) {
            return <CodeSnippet text={el} showCopyCodeButton language="" />;
          }

          return (
            <code style={{ backgroundColor: '#e0e0e0', color: '#000000de' }}>
              {el}
            </code>
          );
        }

        return <span>{el}</span>;
      })}
    </>
  );
};
