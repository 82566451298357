import { Card, CardContent } from '@material-ui/core';
import CheckRounded from '@material-ui/icons/CheckRounded';
import React from 'react';
import { parsedBody, userCrumb } from './Partials';
import { StackOverflowAnswer } from './StackOverflowTypes';

export const StackOverflowAnswerCard = ({
  answer,
  fontSize,
}: {
  answer: StackOverflowAnswer;
  fontSize: string;
}) => {
  return (
    <Card
      variant="outlined"
      style={{
        marginTop: '1rem',
        borderColor: answer.is_accepted ? '#4cc273' : '#0000001f',
        fontSize: fontSize,
      }}
    >
      <CardContent>
        <div>{parsedBody(answer.body)}</div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '0.2rem',
              fontWeight: 'bold',
              alignItems: 'center',
            }}
          >
            {answer.is_accepted && (
              <div>
                <CheckRounded style={{ color: '#4cc273' }} />
              </div>
            )}

            {answer.score}
          </div>

          {userCrumb(answer)}
        </div>
      </CardContent>
    </Card>
  );
};
