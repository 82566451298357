import { Progress } from '@backstage/core-components';
import {
  configApiRef,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import qs from 'qs';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { StackOverflowAnswerCard } from './StackOverflowAnswerCard';
import { getUserLinks } from './StackOverflowHelpers';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  StackOverflowAnswer,
  StackOverflowContentProps,
  StackOverflowPageData,
} from './StackOverflowTypes';

export const StackOverflowModalAnswers = (props: StackOverflowContentProps) => {
  const { question_id, requestParams } = props;
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const config = useApi(configApiRef);
  const siteBaseUrl = config.getString('app.baseUrl');

  const { value, loading, error } =
    useAsync(async (): Promise<StackOverflowPageData> => {
      const baseUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/stack-overflow/api`;
      const params = qs.stringify(requestParams, { addQueryPrefix: true });
      const options = {
        method: 'GET',
      };
      const answerResponse = await fetch(
        `${baseUrl}/questions/${question_id}/answers${params}&filter=withbody`,
        options,
      );
      const answerData = await answerResponse.json();

      // Go through all elements of data and update userLink
      if (answerData.items && answerData.items.length > 0)
        answerData.items = await getUserLinks(
          answerData.items,
          catalogApi,
          siteBaseUrl,
        );

      return { answerData: answerData.items };
    }, []);

  const answerData = value?.answerData;

  let filteredAnswer: StackOverflowAnswer[] | undefined;
  if (answerData) {
    answerData.sort((a, b) => (a.score > b.score ? 1 : -1));
    const acceptedAnswer = answerData.find(answer => answer.is_accepted);
    filteredAnswer = answerData.filter(answer => !answer.is_accepted);

    if (acceptedAnswer) filteredAnswer.unshift(acceptedAnswer);
  }

  if (loading) {
    return <Progress />;
  }

  if (error || !filteredAnswer || !filteredAnswer.length) {
    return <p>{error}</p>;
  }

  return (
    <>
      {filteredAnswer.length > 0
        ? filteredAnswer.map((answer: StackOverflowAnswer) => (
            <StackOverflowAnswerCard answer={answer} fontSize="medium" />
          ))
        : null}
    </>
  );
};
