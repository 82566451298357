import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import {
  StackOverflowAnswer,
  StackOverflowQuestion,
} from './StackOverflowTypes';

export async function getUserLink(
  row: StackOverflowQuestion | StackOverflowAnswer,
  catalogApi: CatalogApi,
  baseUrl: string,
) {
  const stackOverflowLink = row?.owner?.link;

  // If we don't have a SO link at all - just return empty
  if (!stackOverflowLink || stackOverflowLink === '') return '';

  const username = stackOverflowLink.split('/').pop();
  const firstName = username?.split('-')?.[0];
  const lastName = username?.split('-')?.[1];

  // No name means we are unlikely to find Portal match - return SO link
  if (!firstName || !lastName) return stackOverflowLink;

  let entityRef = {
    name: `${firstName}.${lastName}`,
    namespace: DEFAULT_NAMESPACE,
    kind: 'User',
  };

  let catalogUser = await catalogApi.getEntityByRef(entityRef);
  let namePattern = `${firstName}.${lastName}`;

  // We have a user - it's safe to return a link to the user page
  const portalLink = `${baseUrl}/catalog/default/user`;
  if (catalogUser) return `${portalLink}/${namePattern}`;

  // Otherwise we still need to try the other format for username
  namePattern = `${Array.from(firstName[0])}${lastName}`;
  entityRef = {
    name: `${namePattern}`,
    namespace: DEFAULT_NAMESPACE,
    kind: 'User',
  };

  catalogUser = await catalogApi.getEntityByRef(entityRef);
  if (catalogUser) return `${portalLink}/${namePattern}`;

  // No match at this point, just return SO link
  return stackOverflowLink;
}

export async function getUserLinks(
  dataRows: StackOverflowQuestion[] | StackOverflowAnswer[],
  catalogApi: CatalogApi,
  baseUrl: string,
) {
  const allUsers = await catalogApi.getEntities({
    filter: {
      kind: ['User'],
    },
    fields: ['metadata.name'],
  });

  const flattenedAllUsers = allUsers.items.map(user => {
    return user.metadata.name.toLowerCase();
  });

  const filteredArray = dataRows.map(row => {
    const stackOverflowLink = row?.owner?.link;

    // If we don't have a SO link at all - just return empty
    if (!stackOverflowLink || stackOverflowLink === '') {
      return row;
    }

    const username = stackOverflowLink.split('/').pop();
    const firstName = username?.split('-')?.[0];
    const lastName = username?.split('-')?.[1];

    // No name means we are unlikely to find Portal match - return SO link
    if (!firstName || !lastName) {
      return {
        ...row,
        owner: {
          ...row.owner,
          userLink: stackOverflowLink,
        },
      };
    }

    const portalLink = `${baseUrl}/catalog/default/user`;
    const namePatterns = [
      `${firstName}.${lastName}`,
      `${Array.from(firstName[0])}${lastName}`,
    ];
    const matchWithCatalog = namePatterns.filter(pattern => {
      return flattenedAllUsers.includes(pattern);
    });

    // We can return a portal link if there are any matches
    if (matchWithCatalog.length > 0) {
      return {
        ...row,
        owner: {
          ...row.owner,
          userLink: `${portalLink}/${matchWithCatalog[0]}`,
        },
      };
    }

    // No match at this point, just return SO link
    return {
      ...row,
      owner: {
        ...row.owner,
        userLink: stackOverflowLink,
      },
    };
  });

  return filteredArray;
}
