import {
  Button,
  Content,
  Header,
  Link,
  Page,
  Table,
  TableColumn,
} from '@backstage/core-components';
import {
  configApiRef,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import he from 'he';
import qs from 'qs';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { parsedBody, questionTags, userCrumb } from './Partials';
import { getUserLinks } from './StackOverflowHelpers';
import { StackOverflowModalAnswers } from './StackOverflowModalAnswers';
import {
  StackOverflowContentProps,
  StackOverflowQuestion,
  StackOverflowQuestionResponse,
} from './StackOverflowTypes';

const StackOverflowColumns: TableColumn[] = [
  {
    title: '',
    field: 'meta',
    width: '15%',
    render: (row: Partial<StackOverflowQuestion>) => (
      <div
        style={{
          alignItems: 'end',
          gap: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>{row.score} votes</div>
        <div
          style={{
            border: '1px solid #4cc273',
            borderRadius: '2px',
            backgroundColor: row.accepted_answer_id ? '#ade7c0' : 'none',
            color: row.accepted_answer_id ? '#000000de' : 'inherit',
            display: 'flex',
            alignItems: 'center',
            padding: row.accepted_answer_id ? '2px' : '6px',
          }}
        >
          {row.accepted_answer_id ? <CheckIcon fontSize="small" /> : ''}
          {row.answer_count}{' '}
          {(row.answer_count || 0) === 1 ? 'answer' : 'answers'}
        </div>
        <div>{row.view_count} views</div>
      </div>
    ),
  },
  {
    title: 'Question',
    field: 'title',
    render: (row: Partial<StackOverflowQuestion>) => (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Link
          to={`/stack-overflow/${row.question_id}`}
          state={{ question: row }}
          style={{ fontSize: 'larger' }}
        >
          {' '}
          {he.decode(row.title || '')}
        </Link>

        <div>
          {`${he.decode(
            row.body?.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 180) || '',
          )}...`}
        </div>
        {questionTags(row?.tags || [])}
        <div style={{ alignSelf: 'end', display: 'flex', gap: '0.2rem' }}>
          {userCrumb(row as StackOverflowQuestion)}
        </div>
      </div>
    ),
  },
  {
    field: 'tags',
    hidden: true,
    searchable: true,
  },
];

async function getQuestionData(
  props: StackOverflowContentProps,
  page: number,
  baseUrl: string,
) {
  const { requestParams } = props;
  const params = qs.stringify(requestParams, { addQueryPrefix: true });
  const options = {
    method: 'GET',
  };

  const response = await fetch(
    `${baseUrl}/questions${params}&filter=withbody&pagesize=100&page=${page}`,
    options,
  );
  return response.json();
}

const useQuestions = (
  props: StackOverflowContentProps,
): {
  value: StackOverflowQuestion[] | undefined;
  loading: boolean;
  error: Error | undefined;
} => {
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const config = useApi(configApiRef);
  const siteBaseUrl = config.getString('app.baseUrl');

  const { value, loading, error } = useAsync(async (): Promise<
    StackOverflowQuestion[]
  > => {
    let page = 1;
    const baseUrl = `${await discoveryApi.getBaseUrl(
      'proxy',
    )}/stack-overflow/api`;
    const questionData = await getQuestionData(props, page, baseUrl);

    let questionDataCurrentPage: StackOverflowQuestionResponse;
    questionDataCurrentPage = questionData;

    while (questionDataCurrentPage.has_more) {
      page += 1;
      questionDataCurrentPage = await getQuestionData(props, page, baseUrl);
      questionData.items = questionData.items.concat(
        questionDataCurrentPage.items,
      );
    }

    // Go through all elements of data and update userLink
    if (questionData.items && questionData.items.length > 0)
      questionData.items = await getUserLinks(
        questionData.items,
        catalogApi,
        siteBaseUrl,
      );

    return questionData.items;
  }, []);

  return { value, loading, error };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    paper: { maxWidth: '1000px' },
  }),
);

const StackOverflowData = (props: StackOverflowContentProps) => {
  const { value, loading, error } = useQuestions(props);
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState<StackOverflowQuestion>();
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const teamName = configApi.getString('stackoverflow.team');

  if (error) {
    return <p>{error?.message}</p>;
  }

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const loadQuestionModal = (event: any, rowData: StackOverflowQuestion) => {
    if (event.target.nodeName !== 'A') {
      setQuestion(rowData);
      openDialog();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="dialog-title">
          {he.decode(question?.title || '')}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ fontSize: 'small', fontWeight: 'lighter' }}>
            {questionTags(question?.tags || [])}
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            <Typography>{parsedBody(question?.body || '')}</Typography>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                <p>
                  {question?.score} {question?.score === 1 ? 'vote ' : 'votes '}
                  {question?.view_count}{' '}
                  {question?.view_count === 1 ? 'view' : 'views'}
                </p>
              </div>
              <div>{userCrumb(question as StackOverflowQuestion)}</div>
            </div>

            <Divider style={{ marginTop: '1rem' }} />

            <StackOverflowModalAnswers
              question_id={question?.question_id.toString()}
              requestParams={{
                team: teamName,
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button color="primary" to={question?.link || ''}>
            Direct Link
          </Button>
          <Button color="primary" onClick={closeDialog} to="">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        isLoading={loading}
        options={{
          paging: true,
          pageSize: 15,
          search: true,
        }}
        totalCount={value?.length || 0}
        data={value ?? []}
        onRowClick={(event, rowData) => {
          loadQuestionModal(event, rowData as StackOverflowQuestion);
        }}
        title={
          <Box display="flex" alignItems="center">
            <Box mr={2} />
            <Typography variant="h6">
              Power Home Remodeling Questions
            </Typography>
            <Button
              to="https://stackoverflowteams.com/c/powerhome/questions/ask"
              color="default"
              variant="outlined"
              style={{ marginLeft: '2rem' }}
            >
              Ask a Question
            </Button>
          </Box>
        }
        columns={StackOverflowColumns}
        style={{ marginBottom: '2rem', width: '100%' }}
      />
    </>
  );
};

export const StackOverflowPage = () => {
  const config = useApi(configApiRef);
  const teamName = config.getString('stackoverflow.team');

  return (
    <Page themeId="app">
      <Header title="Stack Overflow" pageTitleOverride="Stack Overflow" />
      <Content>
        <StackOverflowData
          requestParams={{
            team: teamName,
          }}
        />
      </Content>
    </Page>
  );
};
