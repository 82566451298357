import React from 'react';
import { Grid } from '@material-ui/core';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@backstage/plugin-pagerduty';
import {
  isJenkinsAvailable,
  EntityLatestJenkinsRunCard,
} from '@backstage/plugin-jenkins';
import { EntitySentryContent } from '@backstage/plugin-sentry';

import { ciContent } from './shared/CiContent';
import { ShipitEnginePage } from '@internal/plugin-shipit-engine';
import { isCdAvailable, isSentryAvailable } from './shared/helpers';

export const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <Grid container>
            <EntitySwitch>
              <EntitySwitch.Case if={isOrphan}>
                <Grid item xs={12}>
                  <EntityOrphanWarning />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>

            <EntitySwitch>
              <EntitySwitch.Case if={hasRelationWarnings}>
                <Grid item xs={12}>
                  <EntityRelationWarning />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>

            <EntitySwitch>
              <EntitySwitch.Case if={hasCatalogProcessingErrors}>
                <Grid item xs={12}>
                  <EntityProcessingErrorsPanel />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>

            <Grid item md={12} sm={12} xs={12}>
              <EntityAboutCard variant="gridItem" />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <EntityCatalogGraphCard variant="gridItem" height={400} />
            </Grid>

            <EntitySwitch>
              <EntitySwitch.Case if={isJenkinsAvailable}>
                <Grid item md={12} sm={12} xs={12}>
                  <EntityLatestJenkinsRunCard
                    branch="main,master"
                    variant="gridItem"
                  />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>
          </Grid>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <EntityLinksCard />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <EntityHasApisCard variant="gridItem" />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <EntityHasResourcesCard variant="gridItem" />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <EntityHasComponentsCard variant="gridItem" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/changes"
      title="Changes"
      if={isGithubPullRequestsAvailable}
    >
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci" title="CI" if={isJenkinsAvailable}>
      {ciContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isCdAvailable} path="/cd" title="CD">
      <ShipitEnginePage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/errors" title="Errors" if={isSentryAvailable}>
      <EntitySentryContent />
    </EntityLayout.Route>

    <EntityLayout.Route if={isPagerDutyAvailable} path="/alerts" title="Alerts">
      <Grid item md={12}>
        <EntityPagerDutyCard />
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
