import {
  Content as ComponentContent,
  Content,
  ContentHeader,
  EmptyState,
  Header,
  ItemCardGrid,
  Page,
  Progress,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { exploreToolsConfigRef } from '@backstage/plugin-explore-react';
import { HeaderWorldClock } from '@backstage/plugin-home';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useClockConfig } from '../../helpers/config-helper';
import { ToolCard } from './ToolCard';

const Body = () => {
  const exploreToolsConfigApi = useApi(exploreToolsConfigRef);
  const {
    value: tools,
    loading,
    error,
  } = useAsync(async () => {
    return await exploreToolsConfigApi.getTools();
  }, [exploreToolsConfigApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <WarningPanel title="Failed to load tools" />;
  }

  if (!tools?.length) {
    return (
      <EmptyState
        missing="info"
        title="No tools to display"
        description="You haven't added any tools yet."
      />
    );
  }

  return (
    <ItemCardGrid>
      {tools.map((tool, index) => (
        <ToolCard key={index} card={tool} />
      ))}
    </ItemCardGrid>
  );
};

export const ToolPage = () => {
  return (
    <Page themeId="tool">
      <Header title="Tools" pageTitleOverride="Tools">
        <HeaderWorldClock clockConfigs={useClockConfig()} />
      </Header>
      <ComponentContent>
        <Content noPadding>
          <ContentHeader title="">
            <SupportButton>Report an issue.</SupportButton>
          </ContentHeader>
          <Body />
        </Content>
      </ComponentContent>
    </Page>
  );
};
