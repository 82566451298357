export function waitForElementToDisplay(selector: any) {
  return new Promise(resolve => {
    (function checkIfElementExists() {
      if (document.querySelector(selector) !== null) {
        resolve(true);
      } else {
        setTimeout(checkIfElementExists, 500);
      }
    })();
  });
}
