import { GetEntitiesResponse } from '@backstage/catalog-client';
import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityRouteRef,
  entityRouteParams,
} from '@backstage/plugin-catalog-react';
import { useMemo } from 'react';
import { Link, Table, TableColumn } from '@backstage/core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Entity } from '../../models/entity';

export const ownedEntitiesColumns: TableColumn[] = [
  {
    title: 'Name',
    field: 'name',
    render: (row: Partial<Entity>) => (
      <div>
        <Link to={`${row.link}`}>{row.name}</Link>
      </div>
    ),
    width: '15%',
  },
  {
    title: 'Description',
    field: 'description',
    render: (row: Partial<Entity>) => <div>{row.description}</div>,
  },
  {
    title: 'Kind',
    field: 'kind',
    render: (row: Partial<Entity>) => <div>{row.kind}</div>,
  },
];

export const OwnedEntities = () => {
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const { ownedEntities, loading } = useOwnedEntities();

  if (ownedEntities?.items.length === 0) return null;

  const data = ownedEntities?.items.map(
    entity =>
      ({
        name: entity.metadata.title ?? entity.metadata.name,
        description: entity.metadata.description,
        kind: entity.kind,
        link: catalogEntityRoute(entityRouteParams(entity)),
      } as Entity),
  );

  return (
    <Grid item xs={12}>
      <Table
        isLoading={loading}
        options={{
          paging: true,
          pageSize: 5,
          padding: 'dense',
        }}
        totalCount={data?.length || 0}
        data={data ?? []}
        title={
          <Box display="flex" alignItems="center">
            <Box mr={2} />
            <Typography variant="h6">Your Owned Entities</Typography>
          </Box>
        }
        columns={ownedEntitiesColumns}
      />
    </Grid>
  );
};

function useOwnedEntities(): {
  loading: boolean;
  ownedEntities: GetEntitiesResponse | undefined;
} {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { loading, value: refs } = useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const identityRefs = identity.ownershipEntityRefs;
    const catalogs = await catalogApi.getEntities({
      filter: {
        [`relations.${RELATION_OWNED_BY}`]: identityRefs || [],
      },
    });
    return catalogs;
  }, []);

  const ownedEntities = useMemo(() => {
    return refs;
  }, [refs]);

  return useMemo(() => ({ loading, ownedEntities }), [loading, ownedEntities]);
}
