import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityRelationWarning,
  EntityProcessingErrorsPanel,
  isOrphan,
  hasRelationWarnings,
  hasCatalogProcessingErrors,
} from '@backstage/plugin-catalog';
import { EntityOwnershipCard } from '@backstage/plugin-org';

import { UserProfileCard } from '../../user/UserProfileCard';
import { OWNERSHIP_ENTITY_FILTER } from './shared/helpers';

export const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntitySwitch>
          <EntitySwitch.Case if={isOrphan}>
            <Grid item xs={12}>
              <EntityOrphanWarning />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={hasRelationWarnings}>
            <Grid item xs={12}>
              <EntityRelationWarning />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={hasCatalogProcessingErrors}>
            <Grid item xs={12}>
              <EntityProcessingErrorsPanel />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <Grid item md={5}>
          <UserProfileCard variant="gridItem" />
        </Grid>

        <Grid item md={7}>
          <EntityOwnershipCard
            variant="gridItem"
            entityFilterKind={OWNERSHIP_ENTITY_FILTER}
          />
        </Grid>

        <Grid item md={5}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
