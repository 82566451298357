import {
  DEFAULT_NAMESPACE,
  DomainEntity,
  GroupEntity,
  parseEntityRef,
  RELATION_CHILD_OF,
} from '@backstage/catalog-model';
import {
  Content as ComponentContent,
  Content,
  ContentHeader,
  EmptyState,
  Header,
  ItemCardGrid,
  Page,
  Progress,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { HeaderWorldClock } from '@backstage/plugin-home';
import { Button } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useClockConfig } from '../../helpers/config-helper';
import { DomainCard } from './DomainCard';

const domainMembers = (domain: DomainEntity, groupList: GroupEntity[]) => {
  const owner = domain.spec.owner;

  return groupList.filter(group =>
    group?.relations?.some(r => {
      const target = parseEntityRef(r.targetRef);
      const namespace = group.metadata.namespace || DEFAULT_NAMESPACE;

      return (
        r.type === RELATION_CHILD_OF &&
        target.name === owner &&
        target.namespace.toLocaleLowerCase('en-US') ===
          namespace.toLocaleLowerCase('en-US')
      );
    }),
  );
};

const Body = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: { kind: 'domain' },
    });
    const groupList = await catalogApi.getEntities({
      filter: { kind: 'Group' },
    });

    return {
      domains: response.items as DomainEntity[],
      groups: groupList.items as GroupEntity[],
    };
  }, [catalogApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not load domains.">
        {error.message}
      </WarningPanel>
    );
  }

  if (!entities?.domains.length) {
    return (
      <EmptyState
        missing="info"
        title="No domains to display"
        description="You haven't added any domains yet."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/descriptor-format#kind-domain"
          >
            Read more
          </Button>
        }
      />
    );
  }

  return (
    <ItemCardGrid>
      {entities.domains.map((entity, index) => (
        <DomainCard
          key={index}
          entity={entity}
          members={domainMembers(entity, entities.groups)}
        />
      ))}
    </ItemCardGrid>
  );
};

export const DomainPage = () => {
  return (
    <Page themeId="home">
      <Header title="Domains" pageTitleOverride="Domains">
        <HeaderWorldClock clockConfigs={useClockConfig()} />
      </Header>
      <ComponentContent>
        <Content noPadding>
          <ContentHeader title="">
            <SupportButton>Report an issue.</SupportButton>
          </ContentHeader>
          <Body />
        </Content>
      </ComponentContent>
    </Page>
  );
};
