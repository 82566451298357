import {
  Button,
  Content,
  Header,
  Page,
  Progress,
} from '@backstage/core-components';
import {
  configApiRef,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';
import he from 'he';
import qs from 'qs';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { parsedBody, questionTags, userCrumb } from './Partials';
import { StackOverflowAnswerCard } from './StackOverflowAnswerCard';
import { getUserLink, getUserLinks } from './StackOverflowHelpers';
import {
  StackOverflowAnswer,
  StackOverflowContentProps,
  StackOverflowPageData,
  StackOverflowQuestion,
} from './StackOverflowTypes';

export const StackOverflowAnswerPageData = (
  props: StackOverflowContentProps,
) => {
  const { question_id, question, requestParams } = props;
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const config = useApi(configApiRef);
  const siteBaseUrl = config.getString('app.baseUrl');

  const { value, loading, error } =
    useAsync(async (): Promise<StackOverflowPageData> => {
      const baseUrl = `${await discoveryApi.getBaseUrl(
        'proxy',
      )}/stack-overflow/api`;
      const params = qs.stringify(requestParams, { addQueryPrefix: true });
      const options = {
        method: 'GET',
      };
      const answerResponse = await fetch(
        `${baseUrl}/questions/${question_id}/answers${params}&filter=withbody`,
        options,
      );
      const answerData = await answerResponse.json();

      // Check for question data here. If we don't have it, we should make another call
      let questionData: StackOverflowQuestion;
      if (!question) {
        const questionResponse = await fetch(
          `${baseUrl}/questions/${question_id}${params}&filter=withbody`,
          options,
        );
        const data = await questionResponse.json();
        questionData = data.items[0];
      } else {
        questionData = question;
      }

      if (questionData)
        questionData.owner.userLink = await getUserLink(
          questionData,
          catalogApi,
          siteBaseUrl,
        );

      // Go through all elements of data and update userLink
      if (answerData.items && answerData.items.length > 0)
        answerData.items = await getUserLinks(
          answerData.items,
          catalogApi,
          siteBaseUrl,
        );

      return { questionData: questionData, answerData: answerData.items };
    }, []);

  const questionData = value?.questionData;
  const answerData = value?.answerData;

  let filteredAnswer: StackOverflowAnswer[] | undefined;
  if (answerData) {
    answerData.sort((a, b) => (a.score > b.score ? 1 : -1));
    const acceptedAnswer = answerData.find(answer => answer.is_accepted);
    filteredAnswer = answerData.filter(answer => !answer.is_accepted);

    if (acceptedAnswer) filteredAnswer.unshift(acceptedAnswer);
  }

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant="h5">
            {he.decode(questionData?.title || '')}
          </Typography>

          <div style={{ fontSize: 'small' }}>
            {questionTags(questionData?.tags || [])}
          </div>

          <div style={{ fontSize: 'larger', marginTop: '1rem' }}>
            {parsedBody(questionData?.body || '')}
          </div>
        </CardContent>
        <CardActions
          style={{ fontSize: 'larger', justifyContent: 'space-between' }}
        >
          <div style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
            <Typography>
              {questionData?.score}{' '}
              {questionData?.score === 1 ? 'vote ' : 'votes '}
              {questionData?.view_count}{' '}
              {questionData?.view_count === 1 ? 'view' : 'views'}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {userCrumb(questionData as StackOverflowQuestion)}
            <Button color="primary" to={questionData?.link || ''}>
              Direct Link
            </Button>
          </div>
        </CardActions>
      </Card>

      <Divider style={{ marginBottom: '1rem', marginTop: '1rem' }} />

      {filteredAnswer?.map((answer: StackOverflowAnswer) => (
        <StackOverflowAnswerCard answer={answer} fontSize="larger" />
      ))}
    </Container>
  );
};

type LocationState = { question: StackOverflowQuestion };
const useQuestionData = () => {
  const location = useLocation();
  const locationState = location?.state as LocationState | undefined;
  if (locationState) {
    const { question } = location.state as LocationState;
    return question;
  }

  return undefined;
};

export const StackOverflowAnswerPage = () => {
  const config = useApi(configApiRef);
  const teamName = config.getString('stackoverflow.team');
  const { question_id } = useParams();
  const questionData = useQuestionData();

  return (
    <Page themeId="app">
      <Header title="Stack Overflow" pageTitleOverride="Stack Overflow" />
      <Content>
        <StackOverflowAnswerPageData
          question_id={question_id}
          question={questionData}
          requestParams={{
            team: teamName,
          }}
        />
      </Content>
    </Page>
  );
};
