/* eslint no-use-before-define: 0 */

import React from 'react';
import {
  Page,
  Content as ComponentContent,
  Link,
  InfoCard,
  Header,
} from '@backstage/core-components';
import { HeaderWorldClock } from '@backstage/plugin-home';
import { Grid } from '@material-ui/core';
import { useClockConfig } from '../../helpers/config-helper';
import { OwnedEntities } from './OwnedEntities';
import { StarredEntities } from './StarredEntities';
import { OnboardingWizard } from '../wizard/WizardComponent';

/* eslint-disable jsx-a11y/media-has-caption */
export const StartPage = () => {
  return (
    <Page themeId="service">
      <Header title="Home" pageTitleOverride="Home">
        <HeaderWorldClock clockConfigs={useClockConfig()} />
      </Header>
      <ComponentContent>
        <Grid container>
          <Grid
            container
            item
            md={6}
            xs={12}
            style={{ alignContent: 'flex-start' }}
          >
            <Grid item xs={12}>
              <InfoCard title="Welcome!">
                Portal is the entry point to life in BT. It provides features
                such as:
                <ul>
                  <li>
                    A{' '}
                    <Link to="https://portal.powerapp.cloud/catalog">
                      Software Catalog
                    </Link>{' '}
                    of all the technologies maintained by BT
                  </li>
                  <li>
                    Aggregated{' '}
                    <Link to="https://portal.powerapp.cloud/docs">
                      Documentation
                    </Link>{' '}
                    built from the entries in the Software Catalog
                  </li>
                </ul>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <InfoCard title="Documentation">
                Portal also acts as a centralized hub for documentation for all
                entries that are defined in the Software Catalog.
                <h3>Getting Started</h3>
                To access documentation in Portal, simply select{' '}
                <Link to="https://portal.powerapp.cloud/docs">Docs</Link> on the
                sidebar, or access the "Docs" tab from a specific entity. You
                can also search for documentation through the search bar.
                <h3>Key Reads</h3>
                <li>
                  <Link to="https://portal.powerapp.cloud/catalog/default/component/bt-handbook/docs">
                    Understanding Power's Organizational Structure, Technology
                    Stack, and Best Practices
                  </Link>
                </li>
                <li>
                  <Link to="https://portal.powerapp.cloud/catalog/default/system/portal/docs/user-guide/authoring-documentation/">
                    Authoring and Structuring Documentation In General
                  </Link>
                </li>
                <li>
                  <Link to="https://portal.powerapp.cloud/catalog/default/system/portal/docs/user-guide/distributing-documentation">
                    Distributing New Documentation Throughout Portal
                  </Link>
                </li>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <InfoCard title="Knowledge Base">
                <h3>Portal Onboarding Tour</h3>
                If you're new to Portal or just want a brief overview of all of
                it's different features, feel free to start the onboarding
                wizard below.
                <OnboardingWizard />
                <h3>Overview Video</h3>
                <video controls src="/introduction.mp4" width="75%">
                  Sorry, your browser doesn't support embedded videos.
                  <track />
                </video>
              </InfoCard>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            xs={12}
            style={{ alignContent: 'flex-start' }}
          >
            <StarredEntities />
            <OwnedEntities />
            <Grid item xs={12}>
              <InfoCard title="Software Catalog">
                The Software Catalog keeps track of ownership, metadata, and
                documentation for BT technologies. With it, you can look through
                the following types of entities:
                <ul>
                  <li>
                    APIs - An interface that is consumed or exposed by a
                    Component
                  </li>
                  <li>
                    Components - A deployable unit of software, tracked in
                    source control
                  </li>
                  <li>Domains - A business-unit or category</li>
                  <li>
                    Groups - Represent organizational entities, such as teams
                  </li>
                  <li>
                    Locations - Used by Portal as references for the catalog to
                    consume
                  </li>
                  <li>
                    Resources - A piece of physical or virtual infrastructure
                    used by a component
                  </li>
                  <li>
                    Systems - Consists of multiple Components and/or APIs that
                    interact with each other, usually tracked in source control
                    across multiple repositories.
                  </li>
                  <li>
                    User - An entity representing a Power employee or
                    contractor.
                  </li>
                </ul>
                <h3>Getting Started</h3>
                To access the Software Catalog in Portal, select{' '}
                <Link to="https://portal.powerapp.cloud/catalog">
                  Catalog
                </Link>{' '}
                on the left-hand sidebar, or search for a specific catalog entry
                via the search bar.
                <h3>Key Reads</h3>
                <li>
                  <Link to="https://portal.powerapp.cloud/catalog/default/system/portal/docs/user-guide/modeling-software/">
                    Modeling New Portal Entries
                  </Link>
                </li>
                <li>
                  <Link to="https://portal.powerapp.cloud/catalog/default/system/portal/docs/user-guide/organizational-structure/">
                    Structuring Organizational Information (Users, Groups,
                    Domains)
                  </Link>
                </li>
              </InfoCard>
            </Grid>
            <Grid item xs={12}>
              <InfoCard title="Other Features">
                Many of Portal's entities are integrated with external services,
                meaning you'll be able to view some of the following services
                within entity pages:
                <ul>
                  <li>CI</li>
                  <li>Milano</li>
                  <li>Github</li>
                  <li>Sentry</li>
                  <li>Pagerduty</li>
                </ul>
                Portal also contains an{' '}
                <Link to="https://portal.powerapp.cloud/explore">Explore</Link>{' '}
                section to allow users to view our Domains, Groups, and Tools.
              </InfoCard>
            </Grid>
          </Grid>
        </Grid>
      </ComponentContent>
    </Page>
  );
};
