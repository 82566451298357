import { ShepherdOptionsWithType } from 'react-shepherd';
import { waitForElementToDisplay } from './helper';

export const steps: ShepherdOptionsWithType[] = [
  {
    id: 'intro',
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back',
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Welcome to Portal!',
    text: [
      "Portal is a platform that centralizes BT's technologies and documentation in one place. This is a tour aimed at acquainting you with some of Portal's basic features.",
    ],
  },
  {
    id: 'home',
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back',
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next',
      },
    ],
    title: 'Home',
    text: "We're currently in the Home page. The Home page has a plethora of information aimed at addressing common questions for new users of Portal. Feel free to read over the information on the page to better familiarize yourself with Portal.",
  },
  {
    id: 'catalog',
    attachTo: {
      element: '.wizard-catalog-step',
      on: 'right',
    },
    title: 'Catalog',
    text: "Let's do a tour of the catalog next. Go ahead and click on the menu item.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
    ],
    advanceOn: {
      selector: '.wizard-catalog-step',
      event: 'click',
    },
  },
  {
    id: 'catalog-2',
    // This is being done because the component needs to actually render before attachTo fires
    beforeShowPromise: () => {
      return new Promise(async resolve => {
        const selector = '.wizard-catalog-step-2';
        await waitForElementToDisplay(selector).then(() => {
          resolve(true);
        });
      });
    },
    attachTo: {
      element: '.wizard-catalog-step-2',
      on: 'bottom',
    },
    title: 'Catalog Content',
    text: "The catalog is where we can find a full list of Power's entities, from applications and domains, to users and groups.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'catalog-3',
    attachTo: {
      element: '.wizard-catalog-step-3',
      on: 'bottom',
    },
    title: 'Catalog Filter',
    text: "Right now, the catalog is filtered to entities of type 'Systems'. You can select other types by clicking on this dropdown.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'catalog-4',
    attachTo: {
      element: '.wizard-catalog-step-4',
      on: 'auto',
    },
    title: 'Catalog Filters',
    text: "By default, the catalog shows all available Power entities. If you'd like to change that to just entities you own, or that you've starred, you can use these filters.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'catalog-5',
    attachTo: {
      element: '.wizard-catalog-step-5',
      on: 'right',
    },
    title: 'Catalog Filters',
    text: 'Additionally, you can also filter by any other team as well. Feel free to explore the catalog page and explore the different entities available.',
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        text: 'Next',
        type: 'next',
      },
    ],
  },
  {
    id: 'search',
    attachTo: {
      element: '.wizard-search-step',
      on: 'right',
    },
    title: 'Search',
    text: [
      "The catalog isn't the only way of finding information. You can also find entities using the left hand search bar. You can also use the search to find relevant documentation and Stack Overflow questions.",
    ],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'menu',
    text: ["Now, let's take a look at a few other menu options."],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'docs',
    attachTo: {
      element: '.wizard-docs-step',
      on: 'right',
    },
    title: 'Documentation',
    text: "The Docs page contains all available documentation in Power's ecosystem. Similar to the catalog, you'll be able to filter the selection by your personally owned Documents, favorite documents, and specific document owners.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'stack-overflow',
    attachTo: {
      element: '.wizard-so-step',
      on: 'right',
    },
    title: 'Stack Overflow',
    text: 'Portal features integration with Stack Overflow. You can freely read and search any Stack Overflow question in the Power Home teams.',
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'bt-handbook',
    attachTo: {
      element: '.wizard-handbook-step',
      on: 'right',
    },
    title: 'BT Handbook',
    text: "The BT Handbook is a comprehensive set of documents detailing Power's values, structures and practices. It's an essential read for both newer and existing members of the BT department.",
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'create',
    attachTo: {
      element: '.wizard-template-step',
      on: 'right',
    },
    title: 'Templates',
    text: 'Templates are a Portal feature that can be used to fill out forms which can be used to create things like Pull Requests in certain repos.',
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Back',
      },
      {
        type: 'next',
        text: 'Next',
      },
    ],
  },
  {
    id: 'end',
    title: 'Enjoy!',
    text: 'Feel free to explore!',
    buttons: [
      {
        type: 'next',
        text: 'End',
      },
    ],
  },
];
