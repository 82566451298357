import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  makeStyles,
  Paper,
  useTheme,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { Link, useContent } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { searchPlugin, SearchType } from '@backstage/plugin-search';
import {
  DefaultResultListItem,
  SearchBar,
  SearchResult,
  SearchResultPager,
} from '@backstage/plugin-search-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { useSearch } from '@backstage/plugin-search-react';
import { StackOverflowSearchResultListItem } from '@backstage/plugin-stack-overflow';

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: 'none',
    display: 'flex',
  },
  input: {
    flex: 1,
  },
  dialogActionsContainer: { padding: theme.spacing(1, 3) },
  viewResultsLink: { verticalAlign: '0.5em' },
}));

export const SearchModal = ({ toggleModal }: { toggleModal: () => void }) => {
  const getSearchLink = useRouteRef(searchPlugin.routes.root);
  const classes = useStyles();

  const { term } = useSearch();
  const { focusContent } = useContent();
  const { transitions } = useTheme();

  const handleResultClick = () => {
    toggleModal();
    setTimeout(focusContent, transitions.duration.leavingScreen);
  };

  const handleKeyPress = () => {
    handleResultClick();
  };

  return (
    <>
      <DialogTitle>
        <Paper className={classes.container}>
          <SearchBar className={classes.input} />
        </Paper>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container>
            <Grid item xs={10}>
              <SearchType.Tabs
                types={[
                  {
                    value: 'software-catalog',
                    name: 'Catalog',
                  },
                  {
                    value: 'techdocs',
                    name: 'Documentation',
                  },
                  {
                    value: 'stack-overflow',
                    name: 'Stack Overflow',
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems="center"
              justifyContent="center"
            >
              <Link
                onClick={() => {
                  toggleModal();
                  setTimeout(focusContent, transitions.duration.leavingScreen);
                }}
                to={`${getSearchLink()}?query=${term}`}
              >
                <span className={classes.viewResultsLink}>
                  View Full Results
                </span>
                <LaunchIcon color="primary" />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs>
            <SearchResult>
              {({ results }) => (
                <List>
                  {results.map(({ type, document }) => {
                    let resultItem;
                    switch (type) {
                      case 'software-catalog':
                        resultItem = (
                          <CatalogSearchResultListItem
                            key={document.location}
                            result={document}
                          />
                        );
                        break;
                      case 'techdocs':
                        resultItem = (
                          <TechDocsSearchResultListItem
                            key={document.location}
                            result={document}
                          />
                        );
                        break;
                      case 'stack-overflow':
                        resultItem = (
                          <StackOverflowSearchResultListItem
                            key={document.location}
                            result={document}
                          />
                        );
                        break;
                      default:
                        resultItem = (
                          <DefaultResultListItem
                            key={document.location}
                            result={document}
                          />
                        );
                    }
                    return (
                      <div
                        role="button"
                        tabIndex={0}
                        key={`${document.location}-btn`}
                        onClick={handleResultClick}
                        onKeyPress={handleKeyPress}
                      >
                        {resultItem}
                      </div>
                    );
                  })}
                </List>
              )}
            </SearchResult>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <SearchResultPager />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
