import {
  createPlugin,
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

const catalogRouteRef = createRouteRef({
  id: 'catalog',
});

const catalogPagePlugin = createPlugin({
  id: 'catalog-page-plugin',
  routes: {
    root: catalogRouteRef,
  },
});

export const CatalogPage = catalogPagePlugin.provide(
  createRoutableExtension({
    name: 'HomePage',
    component: () =>
      import('./components/catalog/CatalogPage').then(m => m.CatalogPageSrc),
    mountPoint: catalogPlugin.routes.catalogIndex,
  }),
);

export const rootRouteRef = createRouteRef({
  id: 'techdocs:index-page',
});

const docPagePlugin = createPlugin({
  id: 'doc-page-plugin',
  routes: {
    root: rootRouteRef,
  },
});

export const DocPage = docPagePlugin.provide(
  createRoutableExtension({
    name: 'DocPage',
    component: () =>
      import('./components/docs/TechdocsHome').then(m => m.TechDocsHome),
    mountPoint: techdocsPlugin.routes.root,
  }),
);
