import { Entity } from '@backstage/catalog-model';

export const isSentryAvailable = (entity: Entity) => {
  const SENTRY_PROJECT_SLUG_ANNOTATION = 'sentry.io/project-slug';
  return Boolean(entity.metadata.annotations?.[SENTRY_PROJECT_SLUG_ANNOTATION]);
};

export const isCdAvailable = (entity: Entity) => {
  const CD_ANNOTATION = 'milano.powerapp.cloud/stack';
  return Boolean(entity.metadata.annotations?.[CD_ANNOTATION]);
};

export const OWNERSHIP_ENTITY_FILTER = [
  'API',
  'Component',
  'Resource',
  'System',
];
