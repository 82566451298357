import { Header, Page, RoutedTabs } from '@backstage/core-components';
import { HeaderWorldClock } from '@backstage/plugin-home';
import React from 'react';
import { useClockConfig } from '../../helpers/config-helper';
import { TeamOverviewContent } from './TeamOverviewContent';
import { TeamRelationshipContent } from './TeamRelationshipContent';

export const TeamPage = () => {
  const tabContent = [
    {
      path: '/overview',
      title: 'Overview',
      children: <TeamOverviewContent />,
    },
    {
      path: '/relationships',
      title: 'Relationships',
      children: <TeamRelationshipContent />,
    },
  ];

  return (
    <Page themeId="service">
      <Header title="Teams" subtitle="">
        <HeaderWorldClock clockConfigs={useClockConfig()} />
      </Header>
      <RoutedTabs routes={tabContent} />
    </Page>
  );
};
