import { GroupEntity, UserEntity } from '@backstage/catalog-model';
import {
  Avatar,
  Button,
  ItemCardHeader,
  Link,
} from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { BackstageTheme } from '@backstage/theme';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { IconLink } from '../shared/IconLink';

const githubTeamUrl = (slug: string): string => {
  return `https://github.com/orgs/${slug.replace(/\//, '/teams/')}`;
};

const styles = (theme: BackstageTheme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      padding: theme.spacing(2, 2, 3),
      backgroundImage: theme.getPageTheme({ themeId: 'service' })
        .backgroundImage,
      backgroundPosition: 0,
      backgroundSize: 'inherit',
    },
  });

const useStyles = makeStyles(styles, { name: 'TeamCardHeader' });

export const TeamCard = (props: {
  entity: GroupEntity;
  members: UserEntity[];
}) => {
  const { entity, members } = props;

  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const url = catalogEntityRoute(entityRouteParams(entity));
  const classes = useStyles();

  return (
    <Card>
      <CardMedia>
        <ItemCardHeader
          title={entity.spec.profile?.displayName}
          classes={{ root: classes.root }}
        >
          {entity.metadata?.annotations?.['github.com/team-slug'] ? (
            <a
              href={githubTeamUrl(
                entity.metadata?.annotations?.['github.com/team-slug'],
              )}
              style={{ display: 'flex' }}
            >
              <GitHubIcon fontSize="small" style={{ marginRight: '0.2rem' }} />
              {entity.metadata?.annotations?.['github.com/team-slug']}
            </a>
          ) : (
            entity.metadata?.name
          )}
        </ItemCardHeader>
      </CardMedia>
      <CardContent>
        {entity.metadata ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div
              style={{
                marginBottom: '5px',
                overflow: 'scroll',
                maxHeight: '6rem',
              }}
            >
              {entity.metadata.description}
            </div>

            {entity.metadata.links && (
              <div style={{ flex: '1' }}>
                {entity.metadata.links.map(link => (
                  <IconLink
                    href={link.url}
                    text={link.title ?? link.url}
                    Icon={LinkIcon}
                  />
                ))}
              </div>
            )}

            <div style={{ display: 'flex', flexWrap: 'wrap', flex: '1' }}>
              {members.map(member => {
                return (
                  <Link
                    to={generatePath(
                      `/catalog/:namespace/user/${member.metadata.name}`,
                      entityRouteParams(member),
                    )}
                  >
                    <Avatar
                      displayName={member.spec.profile?.displayName}
                      picture={member.spec.profile?.picture}
                      customStyles={{
                        height: '2rem',
                        width: '2rem',
                        margin: '1px',
                        fontSize: 'small',
                      }}
                    />
                  </Link>
                );
              })}
            </div>
          </Box>
        ) : null}
      </CardContent>
      <CardActions>
        <Button to={url} color="primary">
          Explore
        </Button>
      </CardActions>
    </Card>
  );
};
