import React, { useContext } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { steps } from './steps';
import { makeStyles, Button } from '@material-ui/core';

import 'shepherd.js/dist/css/shepherd.css';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const useStyles = makeStyles({
  button: {
    marginTop: '1rem',
  },
});

function SidebarTour() {
  const tour = useContext(ShepherdTourContext);
  const classes = useStyles();

  if (!tour) return null;

  return (
    <Button className={classes.button} onClick={tour.start} color="primary">
      Get Started
    </Button>
  );
}

export const OnboardingWizard = () => {
  return (
    <div>
      <ShepherdTour steps={steps} tourOptions={tourOptions}>
        <SidebarTour />
      </ShepherdTour>
    </div>
  );
};
