import {
  TechRadarApi,
  TechRadarLoaderResponse,
  RadarEntry,
  RadarEntrySnapshot,
} from '@backstage/plugin-tech-radar';

export class PowerTechRadar implements TechRadarApi {
  private radarUrl: string;

  constructor(radarUrl: string) {
    this.radarUrl = radarUrl;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const data = await fetch(this.radarUrl).then(res => res.json());

    return {
      ...data,
      entries: data.entries.map((entry: RadarEntry) => ({
        ...entry,
        timeline: entry.timeline.map((timeline: RadarEntrySnapshot) => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
