export const exploreTools = [
  {
    title: 'Connect',
    description: "Power's internal real-time communications tool.",
    url: 'https://nitro.powerhrg.com/connect/',
    image: `/third_party/connect.png`,
    tags: ['connect', 'messaging', 'communication'],
  },
  {
    title: 'Runway',
    description: "Power's task management and scrum tool.",
    url: 'https://nitro.powerhrg.com/runway/',
    image: `/third_party/runway.png`,
    tags: ['runway', 'projects', 'scrum', 'tasks'],
  },
  {
    title: 'Milano',
    description: 'Automated service for deployment of Power applications.',
    url: 'https://milano.powerapp.cloud',
    image: `/third_party/milano.png`,
    tags: ['milano', 'deployments', 'release'],
  },
  {
    title: 'GitHub',
    description:
      'GitHub is a distributed version-control platform where users can collaborate on or adopt open source code projects, fork code, share ideas and more.',
    url: 'https://github.com/powerhome/',
    image: `/third_party/github.png`,
    tags: ['github', 'git', 'version-control'],
  },
  {
    title: 'Grafana',
    description:
      'Multi-platform open-source analytics and interactive visualization web application that provides charts, graphs, and alerts.',
    url: 'https://metrics.powerapp.cloud/',
    image: `/third_party/grafana.png`,
    tags: ['grafana', 'alerts', 'analytics', 'charts', 'graphs', 'metrics'],
  },
  {
    title: 'Jenkins',
    description:
      'Open-source automation server that is used primarily for Continuous Integration at Power.',
    url: 'https://ci.powerapp.cloud',
    image: `/third_party/jenkins.png`,
    tags: ['jenkins', 'builds', 'ci'],
  },
  {
    title: 'Kibana',
    description:
      'Kibana is a free and open user interface that lets you visualize your Elasticsearch data and navigate the Elastic Stack.',
    url: 'https://logging.prod.hq.powerapp.cloud/',
    image: `/third_party/kibana.png`,
    tags: ['kibana', 'elasticsearch', 'logs'],
  },
  {
    title: 'New Relic',
    description:
      'Observability platform built to help engineers create and monitor their software.',
    url: 'https://one.newrelic.com/',
    image: '/third_party/newrelic.png',
    tags: [
      'newrelic',
      'alerts',
      'analytics',
      'errors',
      'monitoring',
      'performance',
    ],
  },
  {
    title: 'PagerDuty',
    description:
      'PagerDuty offers a simple way to identify any active incidents for an entity and the escalation policy.',
    url: 'https://powerhome.pagerduty.com/',
    image: '/third_party/pagerduty.png',
    tags: ['pagerduty', 'alerts', 'errors'],
  },
  {
    title: 'Sentry',
    description:
      'Self-hosted and cloud-based error monitoring that helps software teams discover, triage, and prioritize errors in real-time.',
    url: 'https://sentry.io/power-home-remodeling-group/',
    image: `/third_party/sentry.png`,
    tags: ['sentry', 'errors', 'monitoring'],
  },
];
