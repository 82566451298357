import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const shipitEnginePlugin = createPlugin({
  id: 'shipit-engine',
  routes: {
    root: rootRouteRef,
  },
});

export const ShipitEnginePage = shipitEnginePlugin.provide(
  createRoutableExtension({
    name: 'ShipitEnginePage',
    component: () =>
      import('./components/ShipitComponent/ShipitPage').then(
        m => m.shipitContent,
      ),
    mountPoint: rootRouteRef,
  }),
);
