import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TeamRelationshipDiagram } from './TeamRelationshipDiagram';

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxHeight: '100%',
    minHeight: 0,
  },
});

export const TeamRelationshipContent = (props: { title?: string }) => {
  const classes = useStyles();

  return (
    <Content noPadding stretch className={classes.root}>
      <ContentHeader title={props.title ?? 'Hierarchical Graph'}>
        <SupportButton>Explore your groups.</SupportButton>
      </ContentHeader>
      {/* This diagram is an abomination. We need to replace it */}
      <TeamRelationshipDiagram />
    </Content>
  );
};
