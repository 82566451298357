import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, catalogPlugin } from '@backstage/plugin-catalog';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { explorePlugin } from '@backstage/plugin-explore';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import {
  ScaffolderFieldExtensions,
  ScaffolderPage,
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import Build from '@material-ui/icons/Build';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Code from '@material-ui/icons/Code';
import List from '@material-ui/icons/ListAlt';
import Public from '@material-ui/icons/Public';
import VideoCall from '@material-ui/icons/VideoCall';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis, nitroIdAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { DomainPage } from './components/domains/DomainPage';
import { ToolPage } from './components/tools/ToolPage';
import { TeamPage } from './components/teams/TeamPage';
import { StartPage } from './components/home/StartPage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { CatalogPage, DocPage } from './customizedPlugins';
import { StackOverflowAnswerPage } from './plugins/stack-overflow/StackOverflowAnswerPage';
import { StackOverflowPage } from './plugins/stack-overflow/StackOverflowPage';
import { ExtendedEntityPickerExtension } from './scaffolder/ExtendedEntityPicker';

const nitroIdProvider: SignInProviderConfig = {
  id: 'nitro-id',
  title: 'Sign In',
  message: 'Sign In Using Nitro ID',
  apiRef: nitroIdAuthApiRef,
};

const useGuest = () => {
  const config = useApi(configApiRef);
  return ['development'].includes(config.getString('auth.environment'));
};

const app = createApp({
  apis,
  icons: {
    build: Build,
    code: Code,
    cloudupload: CloudUpload,
    list: List,
    public: Public,
    videocall: VideoCall,
  },
  components: {
    SignInPage: props => {
      const authProviders = [nitroIdProvider];

      if (useGuest()) authProviders.push('guest' as any);

      return <SignInPage {...props} providers={authProviders} />;
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Navigate key="/" to="home" />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <StartPage />
    </Route>
    <Route path="/catalog" element={<CatalogPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<DocPage initialFilter="all" />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <ExtendedEntityPickerExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/tools" element={<ToolPage />} />
    <Route path="/teams" element={<TeamPage />} />
    <Route path="/domains" element={<DomainPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 2,
          }}
        />
      }
    />
    <Route path="stack-overflow" element={<StackOverflowPage />} />
    <Route
      path="stack-overflow/:question_id"
      element={<StackOverflowAnswerPage />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
