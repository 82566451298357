import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  ProfileInfoApi,
  SessionApi,
  OpenIdConnectApi,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { exploreToolsConfigRef } from '@backstage/plugin-explore-react';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';

import { exploreTools } from './plugins/explore';
import { PowerTechRadar } from './plugins/radar';
import { OAuth2 } from '@backstage/core-app-api';

export const nitroIdAuthApiRef: ApiRef<
  OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'internal.auth.nitro-id',
});

export const apis: AnyApiFactory[] = [
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: nitroIdAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'oidc',
          title: 'Nitro ID Provider',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: exploreToolsConfigRef,
    deps: {},
    factory: () => ({
      async getTools() {
        return exploreTools;
      },
    }),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: {
      configApi: configApiRef,
    },
    factory: ({ configApi }) =>
      new PowerTechRadar(configApi.getString('app.radar.url')),
  }),
];
