/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  catalogApiRef,
  useStarredEntities,
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { Progress, Table, TableColumn, Link } from '@backstage/core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Entity } from '../../models/entity';

export const starredEntitiesColumns: TableColumn[] = [
  {
    title: 'Name',
    field: 'name',
    render: (row: Partial<Entity>) => (
      <div>
        <Link to={`${row.link}`}>{row.name}</Link>
      </div>
    ),
    width: '15%',
  },
  {
    title: 'Description',
    field: 'description',
    render: (row: Partial<Entity>) => <div>{row.description}</div>,
  },
  {
    title: 'Kind',
    field: 'kind',
    render: (row: Partial<Entity>) => <div>{row.kind}</div>,
  },
];

export const StarredEntities = () => {
  const catalogApi = useApi(catalogApiRef);
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const { starredEntities } = useStarredEntities();

  // Grab starred entities from catalog to ensure they still exist and also retrieve display titles
  const entities = useAsync(async () => {
    if (!starredEntities.size) {
      return [];
    }

    const filter = [...starredEntities]
      .map(ent => parseEntityRef(ent))
      .map(ref => ({
        kind: ref.kind,
        'metadata.namespace': ref.namespace,
        'metadata.name': ref.name,
      }));

    return (
      await catalogApi.getEntities({
        filter,
        fields: [
          'kind',
          'metadata.namespace',
          'metadata.name',
          'metadata.title',
        ],
      })
    ).items;
  }, [catalogApi, starredEntities]);

  if (starredEntities.size === 0) return null;

  if (entities.loading) {
    return <Progress />;
  }

  const data = entities.value?.map(
    entity =>
      ({
        name: entity.metadata.title ?? entity.metadata.name,
        description: entity.metadata.description,
        kind: entity.kind,
        link: catalogEntityRoute(entityRouteParams(entity)),
      } as Entity),
  );

  return (
    <Grid item xs={12}>
      <Table
        isLoading={entities.loading}
        options={{
          paging: true,
          pageSize: 5,
          padding: 'dense',
        }}
        totalCount={data?.length || 0}
        data={data ?? []}
        title={
          <Box display="flex" alignItems="center">
            <Box mr={2} />
            <Typography variant="h6">Your Starred Entities</Typography>
          </Box>
        }
        columns={starredEntitiesColumns}
      />
    </Grid>
  );
};
