import {
  DEFAULT_NAMESPACE,
  GroupEntity,
  parseEntityRef,
  RELATION_MEMBER_OF,
  UserEntity,
} from '@backstage/catalog-model';
import {
  Content,
  ContentHeader,
  EmptyState,
  ItemCardGrid,
  Progress,
  SupportButton,
  WarningPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { SearchBar } from '@backstage/plugin-search-react';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { TeamCard } from './TeamCard';

const groupMembers = (group: GroupEntity, userList: UserEntity[]) => {
  return userList.filter(user =>
    user?.relations?.some(r => {
      const target = parseEntityRef(r.targetRef);
      const namespace = group.metadata.namespace || DEFAULT_NAMESPACE;

      return (
        r.type === RELATION_MEMBER_OF &&
        target.name.toLocaleLowerCase('en-US') ===
          group.metadata.name.toLocaleLowerCase('en-US') &&
        target.namespace.toLocaleLowerCase('en-US') ===
          namespace.toLocaleLowerCase('en-US')
      );
    }),
  );
};

export const TeamOverviewContent = () => {
  const catalogApi = useApi(catalogApiRef);
  const [teams, setTeams] = useState<any[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<any[]>([]);
  const { loading, error } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: { kind: 'Group', 'spec.type': 'team' }, // Add type as a filter to omit shell teams (teams per domain, etc)
    });

    const userList = await catalogApi.getEntities({
      filter: { kind: 'User' },
    });

    const final = response.items.map((entity: any) => {
      const members = groupMembers(
        entity,
        userList.items as UserEntity[],
      ) as UserEntity[];
      const memberFilters = members
        .map(member => {
          return [member.spec.profile?.displayName, member.spec.profile?.email];
        })
        .flat();
      const groupFilters = [
        entity.metadata.name,
        entity.metadata.title,
        entity.metadata.description,
        entity.metadata.annotations['github.com/team-slug'],
      ];

      entity.filter = memberFilters
        .concat(groupFilters)
        .join('|')
        .toLowerCase();
      entity.members = members;
      return entity;
    });

    setTeams(final);
    setFilteredTeams(final);
  }, [catalogApi]);

  const handleSearch = (e: string) => {
    const newfilter = teams.filter(entity => {
      return entity.filter.includes(e.toLowerCase().trim());
    });
    setFilteredTeams(newfilter);
  };

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <Content noPadding>
        <ContentHeader title="">
          <SupportButton>Report an issue.</SupportButton>
        </ContentHeader>
        <WarningPanel severity="error" title="Could not load teams.">
          {error.message}
        </WarningPanel>
      </Content>
    );
  }

  if (!filteredTeams.length) {
    return (
      <Content noPadding>
        <ContentHeader title="">
          <span style={{ display: 'flex' }}>
            <SearchBar
              clearButton={false}
              onChange={e => handleSearch(e)}
              placeholder="Filter Teams"
            />
            <SupportButton>Report an issue.</SupportButton>
          </span>
        </ContentHeader>
        <EmptyState
          missing="info"
          title="No teams to display"
          description="
            Try adjusting your search filter.
            Click the button below to learn how to add or edit to Portal's organizational structure.
          "
          action={
            <Button
              variant="contained"
              color="primary"
              href="https://portal.powerapp.cloud/docs/default/system/portal/user-guide/organizational-structure/"
            >
              Read more
            </Button>
          }
        />
      </Content>
    );
  }

  return (
    <Content noPadding>
      <ContentHeader title="">
        <span style={{ display: 'flex' }}>
          <SearchBar
            clearButton={false}
            onChange={e => handleSearch(e)}
            placeholder="Filter Teams"
          />
          <SupportButton>Report an issue.</SupportButton>
        </span>
      </ContentHeader>
      <ItemCardGrid>
        {filteredTeams.map((entity, index) => (
          <TeamCard key={index} entity={entity} members={entity.members} />
        ))}
      </ItemCardGrid>
    </Content>
  );
};
