/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  GroupIcon,
  Sidebar,
  sidebarConfig,
  SidebarContext,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { Link as MaterialLink, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BuildIcon from '@material-ui/icons/Build';
import DomainIcon from '@material-ui/icons/Domain';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PagesIcon from '@material-ui/icons/Pages';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SearchIcon from '@material-ui/icons/Search';
import React, { PropsWithChildren, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SearchModal } from '../search/SearchModal';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className={classes.root}>
      <MaterialLink
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </MaterialLink>
    </div>
  );
};

const useBTHandbook = () => {
  const config = useApi(configApiRef);
  return !['development'].includes(config.getString('auth.environment'));
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <div className="wizard-search-step">
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal>
            {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
          </SidebarSearchModal>
        </SidebarGroup>
      </div>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem
          className="wizard-home-step"
          icon={HomeIcon}
          to="home"
          text="Home"
        />
        <SidebarItem
          className="wizard-catalog-step"
          icon={PagesIcon}
          to="catalog"
          text="Catalog"
        />
        <SidebarItem icon={GroupIcon} to="teams" text="Teams" />
        <SidebarItem icon={DomainIcon} to="domains" text="Domains" />
        <SidebarItem icon={BuildIcon} to="tools" text="Tools" />
        <SidebarDivider />
        <SidebarItem
          className="wizard-docs-step"
          icon={LibraryBooks}
          to="docs"
          text="Docs"
        />
        {useBTHandbook() ? (
          <SidebarItem
            className="wizard-handbook-step"
            icon={MenuBookIcon}
            to="docs/default/Component/bt-handbook"
            text="BT Handbook"
          />
        ) : (
          ''
        )}
        <SidebarItem
          className="wizard-techradar-step"
          icon={TrackChangesIcon}
          to="tech-radar"
          text="Tech Radar"
        />
        <SidebarItem
          className="wizard-so-step"
          icon={QuestionAnswerIcon}
          to="stack-overflow"
          text="Stack Overflow"
        />
        <SidebarDivider />
        <SidebarItem
          className="wizard-template-step"
          icon={AddCircleIcon}
          to="create"
          text="Create"
        />
        {/* End global nav */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
