import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { ClockConfig } from '@backstage/plugin-home';

export const useClockConfig = () => {
  const config = useApi(configApiRef);
  return config.getConfigArray('homepage.clocks').map((clock): ClockConfig => {
    return {
      label: clock.getString('label'),
      timeZone: clock.getString('timezone'),
    };
  });
};
