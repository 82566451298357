import {
  scaffolderPlugin,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder';
import { ExtendedEntityPicker } from './ExtendedEntityPicker';

export const ExtendedEntityPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ExtendedEntityPicker',
    component: ExtendedEntityPicker,
  }),
);
