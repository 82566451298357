/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  darkBlue: {
    fill: '#0072ce',
  },
  lightBlue: {
    fill: '#009cde',
  },
  text: {
    fill: '#fff',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 653.11 60.68"
    >
      <g id="A">
        <path
          className={classes.darkBlue}
          d="M24.64,38v7.44a.23.23,0,0,1-.18.22l-5.41,1a.12.12,0,0,1-.13,0,.16.16,0,0,1,0-.15l.32-1.54V25.68l-.32-1.42a.23.23,0,0,1,.17-.25l6.8-1.2V10.35h0L1,28a.9.9,0,0,0-.39.76V45.88a.93.93,0,0,0,.39.76l0,0L24.81,63.52a.66.66,0,0,0,1-.54V37.84Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.lightBlue}
          d="M53.1,19.36l0,0L29.28,2.48A.66.66,0,0,0,28.23,3V22.45c2.54-.21,4.41.41,5.57,1.85a7.13,7.13,0,0,1,1.32,4.36A9.29,9.29,0,0,1,33.8,33.5a9,9,0,0,1-5.57,3.83V55.65L53.1,38a.94.94,0,0,0,.39-.76V20.12A1,1,0,0,0,53.1,19.36Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M74.21,35.93v7.63H70.43V32.78H87.16c4.45,0,8-1.26,8-4.65,0-3.64-3.29-4.76-8.08-4.76H70.36l2.52-3.22H88.46c6.44,0,10.53,3,10.53,8s-4.06,7.77-10.53,7.77Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M117.51,44C107,44,101.79,40.2,101.79,31.84S107,19.76,117.51,19.76s15.71,3.71,15.71,12.08S128,44,117.51,44Zm0-21c-8.3,0-11.94,2.31-11.94,8.89s3.64,8.92,11.94,8.92,11.93-2.34,11.93-8.92S125.8,23,117.51,23Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M162.31,43.56l-8.37-7.73H140.5v7.73h-3.78V32.78h16.77c4.37,0,8.33-1.26,8.33-4.62,0-3.71-3.47-4.79-8.33-4.79H136.65l2.49-3.22h15.75c5.46,0,10.81,2.1,10.81,7.8,0,4.2-3.08,6.76-7.84,7.18l9.59,8.43Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M180.23,43.56V23.37H167.8l2.56-3.22h27.09L195,23.37H184V43.56Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M200.53,43.56l2.31-3.18h16.31L208.37,24.14,195.24,43.56h-4.48L206,21.27a3,3,0,0,1,2.49-1.44,2.92,2.92,0,0,1,2.48,1.44l15,22.29Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.text}
          d="M228.32,43.56V20.15h3.78V40.38h19.53l-2.49,3.18Z"
          transform="translate(-0.6 -2.36)"
        />
        <text x="-0.6" y="-2.36" />
      </g>
    </svg>
  );
};

export default LogoFull;
