/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  darkBlue: {
    fill: '#0072ce',
  },
  lightBlue: {
    fill: '#009cde',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52.89 61.28"
    >
      <g id="A">
        <path
          className={classes.darkBlue}
          d="M24.64,38v7.44a.23.23,0,0,1-.18.22l-5.41,1a.12.12,0,0,1-.13,0,.16.16,0,0,1,0-.15l.32-1.54V25.68l-.32-1.42a.23.23,0,0,1,.17-.25l6.8-1.2V10.35h0L1,28a.9.9,0,0,0-.39.76V45.88a.93.93,0,0,0,.39.76l0,0L24.81,63.52a.66.66,0,0,0,1-.54V37.84Z"
          transform="translate(-0.6 -2.36)"
        />
        <path
          className={classes.lightBlue}
          d="M53.1,19.36l0,0L29.28,2.48A.66.66,0,0,0,28.23,3V22.45c2.54-.21,4.41.41,5.57,1.85a7.13,7.13,0,0,1,1.32,4.36A9.29,9.29,0,0,1,33.8,33.5a9,9,0,0,1-5.57,3.83V55.65L53.1,38a.94.94,0,0,0,.39-.76V20.12A1,1,0,0,0,53.1,19.36Z"
          transform="translate(-0.6 -2.36)"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
